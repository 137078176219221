/* fonts.css */

@font-face {
    font-family: 'Nexa';
    src: url('./assets/fonts/Nexa\ Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Nexa';
    src: url('./assets/fonts/Nexa\ Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  